html, body {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

#root {
  box-sizing: border-box;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.page-footer {
  flex-grow: 0;
  flex-shrink: 0;
}

.page-body {
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

@import url('https://fonts.googleapis.com/css?family=Special+Elite:regular,bold,italic&subset=latin,latin-ext');

.app-name {
    font-family: "Special Elite", "Special Elite", "Roboto","Helvetica","Arial", sans-serif !important;
}
